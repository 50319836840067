import { useEffect } from 'react';

const useScanner = <T>(
  cb: (data: T[]) => void,
  minimalLength: number = 0,
  options: T[] = [],
  cmpCb: (a: T, b: string) => boolean,
  ignoreValues: string[] = [],
  acceptOnly: string[] = []
) => {
  const onKeyUp = ({ key }: { key: string }) => {
    if (key === 'TVSatelliteToggle' || key === 'Unidentified') {
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannedText = '';
      window.scanning = true;
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
      }, 5000);
    }
  };

  const onKeyPress = ({ key }: { key: string }) => {
    if (window.scanning) {
      if (key === 'Enter') {
        return;
      }
      window.scannedText += key;
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          window.scannerTimeout = null;
        }

        if (
          window.scannedText?.length >= minimalLength &&
          !ignoreValues.includes(window.scannedText) &&
          (acceptOnly.length === 0 || acceptOnly.includes(window.scannedText))
        ) {
          cb(options.filter((option) => cmpCb(option, window.scannedText)));
        }
        window.scannedText = '';
      }, 50);
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keypress', onKeyPress);
    };
  }, []);
};
export const useScannerRaw = (
  cb: (data: string) => void,
  minimalLength: number = 0,
  errorCb?: () => void
) => {
  const onKeyUp = ({ key }: { key: string }) => {
    if (key === 'TVSatelliteToggle' || key === 'Unidentified') {
      if (window.scanning && window.scannedText.length > 0) {
        console.log('error', window.scannedText, window.scanning);
        // new Audio(
        //   'https://soundspunos.com/uploads/files/2022-10/1666781694_1561356180_7689335a082e02b.mp3'
        // ).play();
        errorCb?.();
        return;
      }
      if (window.scannerTimeout) {
        if (window.scannedText.length > 0) {
          if (window.scannedText.length >= minimalLength) {
            console.log('cb', window.scannedText, window.scanning);
            // new Audio(
            //   'https://soundspunos.com/uploads/files/2022-11/1667756003_1575882047_5e1cc111562c405.mp3'
            // ).play();
            cb(window.scannedText);
          }
          window.scannedText = '';
        }
        clearTimeout(window.scannerTimeout);
      }
      window.scannedText = '';
      window.scanning = true;
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
      }, 10000);
    }
  };

  const onKeyPress = ({ key }: { key: string }) => {
    if (window.scanning) {
      if (key === 'Enter') {
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
        window.scanning = false;
        if (window.scannedText?.length >= minimalLength) {
          console.log('cb', window.scannedText, window.scanning);
          // new Audio(
          //   'https://soundspunos.com/uploads/files/2022-11/1667756003_1575882047_5e1cc111562c405.mp3'
          // ).play();
          cb(window.scannedText);
        }
        window.scannedText = '';
      } else {
        window.scannedText += key;
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
        window.scannerTimeout = setTimeout(() => {
          window.scanning = false;
          if (window.scannerTimeout) {
            clearTimeout(window.scannerTimeout);
          }

          if (window.scannedText?.length >= minimalLength) {
            console.log('cb', window.scannedText, window.scanning);
            // new Audio(
            //   'https://soundspunos.com/uploads/files/2022-11/1667756003_1575882047_5e1cc111562c405.mp3'
            // ).play();
            cb(window.scannedText);
          }
          window.scannedText = '';
        }, 120);
      }
    }
  };

  const onFakeScanner = (ev: any) => {
    cb(ev.detail);
  }
  useEffect(() => {
    console.log('mount')
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keypress', onKeyPress);
    window.addEventListener('fakeScan', onFakeScanner);
    return () => {
      console.log('unmount')
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keypress', onKeyPress);
      window.removeEventListener('fakeScan', onFakeScanner);
    };
  }, [cb]);
};
export const useScannerRawOld = (cb: (data: string) => void, minimalLength: number = 0) => {
  const onKeyUp = ({ key }: { key: string }) => {
    if (key === 'TVSatelliteToggle' || key === 'Unidentified') {
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannedText = '';
      window.scanning = true;
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
      }, 5000);
    }
  };

  const onKeyPress = ({ key }: { key: string }) => {
    if (window.scanning) {
      if (key === 'Enter') {
        return;
      }
      window.scannedText += key;
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          window.scannerTimeout = null;
        }

        if (window.scannedText?.length >= minimalLength) {
          console.log('cb', window.scannedText, window.scanning);
          cb(window.scannedText);
        }
        window.scannedText = '';
      }, 50);
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keypress', onKeyPress);
    };
  }, []);
};

export default useScanner;

import { Bag, BagItem } from '../../redux/api/Types';
import Box from '@mui/material/Box/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { CropFree, Done } from '@mui/icons-material';
import { Badge, Chip } from '@mui/material';
import { ScannedBag } from './BagScanner';

function Codes(props: Pick<BagItem, 'codes'>) {

  return <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

    <Chip
      size="small"
      avatar={<Avatar>V1</Avatar>}
      label={props.codes.v1}
      variant="outlined"
    />

    <Chip
      size="small"
      avatar={<Avatar>V2</Avatar>}
      label={props.codes.v2}
      variant="outlined"
    />

    <Chip
      size="small"
      avatar={<Avatar>V3</Avatar>}
      label={props.codes.v3}
      variant="outlined"
    />

  </Box>;
}

interface BagProps {
  bag: ScannedBag;
}

export default function BagComponent(props: BagProps) {
  const { bag } = props;
  return <>
    <Box sx={{ boxShadow: 3, borderRadius: 1, p: 1, textAlign: 'center',bgcolor: 'background.paper' }}>
      ID: {bag.id}<br/>
      Torba dostarczana pod adres:
      <br />
      {bag.address?.zone?.name && <>{bag.address?.zone?.name} - </>} {bag.address?.city}
    </Box>
    <h3>Zawartość</h3>
    <List sx={{ width: '100%', bgcolor: 'background.paper', boxShadow: 3, borderRadius: 1 }}>
      {bag.items.map((item, i) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{background: item.scanned ? '#4caf50': '#cdcdcd' }}>
              {item.scanned && (<Done/>)}
              {!item.scanned && (<CropFree />)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.name} secondary={<Codes  codes={item.codes}/>} />
        </ListItem>
      ))}
    </List>
  </>;
}

import { RootState } from '../Store';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { login, logout } from '../features/UserSlice';
import { Mutex } from 'async-mutex';
import { authApi } from './AuthApi';
import { LoginResponse } from './Types';
import { toast } from 'react-toastify';
export const BASE_URL = 'https://api.dev.caterings.pl';
// export const BASE_URL = 'https://api.ecm2.stalflex.pl';

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/`
});
const mutex = new Mutex();
export const authorizedBaseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/`,
  prepareHeaders: (headers, { getState }) => {
    const state: RootState = getState() as RootState;
    const token = state.userState.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

export const refreshableBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const state: RootState = api.getState() as RootState;
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await authorizedBaseQuery(args, api, extraOptions);

  if ((result.error?.status as number) === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        try {
          const refreshToken = state.userState.refreshToken;
          if (refreshToken) {
            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ refresh_token: state.userState.refreshToken }) // Przekazanie ciała
            };

            const response = await fetch(`${BASE_URL}/refresh-token`, requestOptions);
            const refreshData = (await response.json()) as LoginResponse;
            api.dispatch(login(refreshData));
            result = await baseQuery(args, api, extraOptions);
          } else {
            toast.error('Zostałeś wylogowany z powodu utraty ważności tokenu', {
              position: 'top-right',
              autoClose: 4500
            });
            api.dispatch(logout());
          }
        } catch (error) {
          toast.error('Zostałeś wylogowany z powodu utraty ważności tokenu', {
            position: 'top-right',
            autoClose: 4500
          });
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await authorizedBaseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default { BASE_URL, authorizedBaseQuery, refreshableBaseQuery };

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { getDocumentTypeColor } from '../utils/Colors';
import BagScanner from '../components/BagScanner/BagScanner';

const DocumentTypeBlock = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  color: 'white'
}));

export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        spacing={3}
        textAlign="center"
        justifyContent="space-between"
        sx={{ p: 2 }}>
        <Grid item xs={12}>
          <BagScanner/>
        </Grid>
      </Grid>
    </>
  );
}

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { object, string, TypeOf } from 'zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginUserMutation } from '../../redux/api/AuthApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import FormInput from '../../components/form/FormInput';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/Store';

const loginWithPasswordSchema = object({
  username: string().min(1, 'Email jest wymagany'),
  password: string()
    .min(1, 'Hasło jest wymagane')
    .min(8, 'Hasło musi być dłuższe niż 8 znaków')
    .max(32, 'Hasło musi być krótsze niż 32 znaki')
});
const loginWithPinSchema = object({
  pin: string().length(6, 'Pin musi mieć 6 znaków')
});
export type LoginWithPasswordInput = TypeOf<typeof loginWithPasswordSchema>;
export type LoginWithPinInput = TypeOf<typeof loginWithPinSchema>;

export default function Login() {
  const navigate = useNavigate();
  const methods = useForm<LoginWithPasswordInput>({
    resolver: zodResolver(loginWithPasswordSchema)
  });
  const [loginUser, { isLoading, isError, error, isSuccess }] = useLoginUserMutation();
  const currentUserId = useAppSelector((state) => state.userState?.user?.id);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    if (isLoading) {
      toast.info('Logowanie w toku', { autoClose: 500 });
      return;
    }
    if (isSuccess) {
      toast.success('Zalogowałeś się poprawnie', { autoClose: 2500 });
      navigate('/app/warehouse/dashboard');
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
            autoClose: 4500
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
          autoClose: 4500
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (currentUserId) {
      navigate('/app/warehouse/dashboard');
    }
  }, [currentUserId]);

  const onSubmitWithPasswordHandler: SubmitHandler<LoginWithPasswordInput> = async (values) => {
    loginUser(values);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img src="/pwa/icon192.png" alt="logo" style={{ maxWidth: '100%' }} />
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitWithPasswordHandler)}
            noValidate
            sx={{ mt: 1 }}>
            <FormInput required id="username" label="Username" name="username" />
            <FormInput required name="password" label="Hasło" type="password" id="password" />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="small"
              sx={{ mt: 3, mb: 2 }}
              loading={false}>
              Zalogj
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
}

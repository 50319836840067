import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppContextState {
}
const initialState: AppContextState = {
  warehouse: null
};

export const appContext = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
  }
});

export default appContext.reducer;

export const {  } = appContext.actions;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LoginWithPasswordInput, LoginWithPinInput } from '../../pages/auth/Login';
import { IGenericResponse, LoginResponse } from './Types';
import { userApi } from './UserApi';
import { login, setUser } from '../features/UserSlice';
import { BASE_URL } from './ApiHelpers';
import { RootState } from '../Store';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.delete('access-control-request-headers');
      headers.delete('access-control-request-method');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation<LoginResponse, LoginWithPasswordInput>({
      query(data) {
        return {
          url: 'login_check',
          method: 'POST',
          body: data
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (error) {
          console.log(error);
        }
      }
    }),
    refreshToken: builder.mutation<LoginResponse, null>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const state = queryApi.getState() as RootState;
        const token = state.userState.refreshToken;
        // return {
        //   url: 'login_check',
        //   method: 'POST',
        //   body: {}
        // };

        try {
          const { data } = await baseQuery({
            url: 'refresh-token',
            method: 'POST',
            body: {
              refresh_token: token
            }
          });
          return { data } as { data: LoginResponse };
        } catch (error) {
          console.log(error);
          return {
            error: {
              status: 401,
              statusText: 'Token expired',
              data: 'Token expired.'
            }
          };
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
          await dispatch(userApi.endpoints.getMyPermissions.initiate(null));
        } catch (error) {
          console.log(error);
        }
      }
    }),
  })
});

export const { useLoginUserMutation, useRefreshTokenMutation } = authApi;

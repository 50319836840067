import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userApi } from '../../redux/api/UserApi';
import FullScreenLoader from './FullScreenLoader';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { logout } from '../../redux/features/UserSlice';

const RequireLoggedUser = ({ requiredRoles }: { requiredRoles: string[] }) => {
  const location = useLocation();
  const token = useAppSelector((state) => state.userState.token);
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, isError, data } = userApi.endpoints?.getMyPermissions.useQuery(
    token,
    {
      skip: false,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true
    }
  );

  const loading = isLoading || isFetching;

  const userPermissions: string[] = userApi.endpoints.getMyPermissions.useQueryState(token, {
    selectFromResult: ({ currentData }) => currentData ?? []
  });
  console.log(userPermissions);

  if (!loading && isError) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  if (loading) {
    return <FullScreenLoader />;
  }

  const allowed =
    userPermissions.length > 0 &&
    requiredRoles.filter((el) => !userPermissions.includes(el)).length === 0;

  if (allowed) {
    return <Outlet />;
  }
  dispatch(logout());
  return <Navigate to="/auth/login" state={{ from: location }} replace />;
};

export default RequireLoggedUser;

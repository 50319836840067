import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import useScanner, { useScannerRaw, useScannerRawOld } from '../hooks/Scanner';
import { toast } from 'react-toastify';

const DocumentTypeBlock = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  color: 'white'
}));

export default function ScannerTestOld() {
  const [scannerData, setScannerData] = React.useState<string[]>([]);
  useScannerRawOld((data) => {
    if (data.length === 0) {
      toast.error('Brak wyniku ');
      return;
    } else {
      // toast.success('Zeskanowano');
      setScannerData((prev) => [...prev, data]);
    }
  });

  return (
    <>
      <div>
        <h1>Skaner test (stary)</h1>
        <p>Wynik skanowania: {scannerData.length}</p>
      </div>
      <pre>
        {scannerData.map((el, i) => (
          <div key={i}>
            #{i + 1}: {el}
          </div>
        ))}
      </pre>
    </>
  );
}

import { CropFree, Dangerous, Phone, Warning } from '@mui/icons-material';
import { Alert, Paper } from '@mui/material';
import { useScannerRaw } from '../../hooks/Scanner';
import BagComponent from './Bag';

import bagData from './../../data/bags.json';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Address, Bag, BagItem } from '../../redux/api/Types';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import FakeScanner from './FakeScanner';

const ScannerHandler = memo(({onScan}: {onScan: (code: string) => void}) => {
  const [scannerData, setScannerData] = useState<string|null>(null);
  const handleScanner = useCallback((code: string) => {
      setScannerData(code);
  }, []);

  useEffect(() => {
    onScan(scannerData ?? '');
    setScannerData(null);
  }, [scannerData]);

  console.log('render');
  useScannerRaw(handleScanner, 0);

  return <></>
});

export type ScannedBagItem = {
  type: string;
  name: string;
  scanned?: boolean;
  codes: {
    v1: string;
    v2: string;
    v3: string;
  }
}
export type ScannedBag = {
  id: number;
  diet: string;
  variant: string;
  calorific: string;
  address: Address | null,
  items: ScannedBagItem[];
}

export default function BagScanner() {
  // const bag = useRef<ScannedBag | null>(null);
  const [bag, setBag] = useState<ScannedBag | null>(null);


  const handleScanBag = useCallback((bagCode: string) => {
    console.log('handleScanBag')
    const scannedBag = bagData.find((el) => (el.id + '')===bagCode) as ScannedBag;
    console.log(scannedBag);
    if (scannedBag) {
      // bag.current = scannedBag;
      setBag(JSON.parse(JSON.stringify(scannedBag)));
      console.log(bag);
    } else {
      console.log('Nie znaleziono torby, sprawdź czy kod kreskowy nie został uszkodzony ');
      toast.error('Nie znaleziono torby, sprawdź czy kod kreskowy nie został uszkodzony ');
    }
  }, [JSON.stringify(bagData)]);

  const handleScanBagElement = useCallback((code: string) => {
    // if (!bag.current) {
    // if (!bag) {
    //   return;
    // }

    // const scannedBagItems = bag.current?.items.filter((el) => el.codes.v1===code || el.codes.v2===code || el.codes.v3===code) ?? [];
    // if (scannedBagItems.length > 0) {
    //   const alreadyScanned = scannedBagItems.filter((el) => el.scanned);
    //   if (alreadyScanned.length===scannedBagItems.length) {
    //     toast.error('Ten element został już zeskanowany w odpowiedniej ilości!');
    //   } else {
    //     let flagFired = false;
    //
    //     toast.success('Zeskanowano element');
    //     bag.current.items = bag.current.items.map((el) => {
    //       if (!flagFired && (el.codes.v1===code || el.codes.v2===code || el.codes.v3===code)) {
    //         el.scanned = true;
    //         flagFired = true;
    //       }
    //       return { ...el };
    //     });
    //   }
    // } else {
    //   toast.error('Zeskanowany element nie powinien trafić do tej torby!');
    // }
    setBag((prevBag) => {
      if (!prevBag) {
        return null;
      }
      const scannedBagItems = prevBag?.items.filter((el) => el.codes.v1===code || el.codes.v2===code || el.codes.v3===code) ?? [];
      if (scannedBagItems.length > 0) {
        const alreadyScanned = scannedBagItems.filter((el) => el.scanned);
        if (alreadyScanned.length===scannedBagItems.length) {
          toast.error('Ten element został już zeskanowany w odpowiedniej ilości!');
          console.log('Ten element został już zeskanowany w odpowiedniej ilości!');
          new Audio(
            '/media/error.mp3'
          ).play();
        } else {
          let flagFired = false;

          toast.success('Zeskanowano element');
          console.log('Zeskanowano element');
          return {
            ...prevBag,
            items: prevBag.items.map((el) => {
              if (!flagFired && (el.codes.v1===code || el.codes.v2===code || el.codes.v3===code)) {
                el.scanned = true;
                flagFired = true;
              }
              return { ...el };
            })
          } as ScannedBag;
        }
      } else {
        toast.error('Zeskanowany element nie powinien trafić do tej torby!');
        console.log('Zeskanowany element nie powinien trafić do tej torby!');
        new Audio(
          '/media/error.mp3'
        ).play();
      }

      return prevBag;
    });
  }, []);

  useEffect(() => {
    if (bag?.items.filter((el) => !el.scanned).length===0) {
      toast.success('Torba została skompletowana!');
      new Audio(
        '/media/success.mp3'
      ).play();
    }
  }, [JSON.stringify(bag)]);


  const handleScanner = useCallback((code: string) => {
    if (code === '') {
      return;
    }
    if (bag) {
      handleScanBagElement(code);
    } else {
      handleScanBag(code);
    }
  }, [JSON.stringify(bag), handleScanBagElement, handleScanBag]);

  console.log('render');
  // useScannerRaw(handleScanner, 0);

  // return (
  //   <div>
  //
  //     {!bag.current && <><Alert variant='standard' color='info' icon={<CropFree />}>
  //       Zeskanuj torbę żeby rozpocząć kompletowanie
  //     </Alert></>}
  //     {bag.current && <>
  //       <BagComponent key={JSON.stringify(bag.current)} bag={bag.current} />
  //
  //       <Button sx={{ width: '100%', mt: 4, p: 2, color: '#fff' }} onClick={() => bag.current = null}>
  //         Zakończ kompletowanie
  //       </Button>
  //     </>}
  //
  //
  //     <FakeScanner defaultValue="105468"/>
  //     <FakeScanner defaultValue="456/sn/o"/>
  //     <FakeScanner defaultValue="789/ob/s"/>
  //     <FakeScanner defaultValue="123/ko/g"/>
  //   </div>
  // );

  return (
    <div>

      <ScannerHandler onScan={handleScanner}/>
      {!bag && <><Alert variant='standard' color='info' icon={<CropFree />}>
        Zeskanuj torbę żeby rozpocząć kompletowanie
      </Alert></>}
      {bag && <>
        <BagComponent bag={bag} />

        <Button sx={{ width: '100%', mt: 4, p: 2, color: '#fff' }} onClick={() => setBag(null)}>
          Zakończ kompletowanie
        </Button>
      </>}


      {/*<FakeScanner defaultValue="105468"/>*/}
      {/*<FakeScanner defaultValue="456/sn/o"/>*/}
      {/*<FakeScanner defaultValue="789/ob/s"/>*/}
      {/*<FakeScanner defaultValue="123/ko/g"/>*/}
    </div>
  );
}
